/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './testimonials.css'
import { testimonialsData } from '../../data/testimonialsData'
import { useState } from 'react'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
import {motion} from 'framer-motion'

const Testimonials = () => {
    const transition = {type: 'spring', duration: 3};
    const [Selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;

  return (
    <div className='testimonials' id='testimonials'>
        <div className="left-t">
            <span>testimonials</span>
            <span className='stroke-text'>what they</span>
            <span>say about us</span>

            <motion.span
             key={Selected}
             transition={transition}
             initial={{opacity:0, x:-100}}
             animate={{opacity:1, x:0}}
             exit={{opacity:0, x:100}} >
                {testimonialsData[Selected].review}
            </motion.span>
            <span>
                <span style={{color: 'var(--orange)'}}>
                    {testimonialsData[Selected].name}
                </span>
                {" "}
                - {testimonialsData[Selected].status}
            </span>
        </div>

        <div className="right-t">
            <motion.div
            transition={{...transition, duration:2}}
            initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}}>
            </motion.div>
            <motion.div
            transition={{...transition, duration:2}}
            initial={{opacity:0, x:100}}
            whileInView={{opacity:1, x:0}}>
            </motion.div>
            <motion.img src={testimonialsData[Selected].image} alt=""
             key={Selected}
             transition={transition}
             initial={{opacity:0, x:100}}
             animate={{opacity:1, x:0}}
             exit={{opacity:0, x:-100}} />

            <div className="arrows">
                
                <img src={leftArrow} onClick={()=>
                {Selected===0
                    ? setSelected(tLength -1)
                    : setSelected((prev)=>prev -1)}} />
                
                <img src={rightArrow} onClick={()=>
                {Selected===tLength -1
                    ? setSelected(0)
                    : setSelected((prev)=>prev +1)}} />

            </div>
        </div>
    </div>
  )
}

export default Testimonials