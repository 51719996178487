import './App.css';
import Hero from './components/Hero/hero'
import Programs from './components/Programs/programs'
import Reasons from './components/Reason/reason'
import Plans from './components/Plans/plans'
import Testimonials from './components/Testimonials/testimonials'
import Join from './components/Join/Join'
import Footer from './components/Footer/footer'

function App() {
  return (
    <div className="App">
          <Hero/>
          <Programs/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
