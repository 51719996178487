import React from 'react'
import './footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import LinkedIn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'

const footer = () => {
  return (
    <div classname="footer-container">
      
      <hr/>
      <div className="footer">
          <div className="social-links">
              <img src={Github} />
              <img src={Instagram} />
              <img src={LinkedIn} />
          </div>
          <div className="logo-f">
              <img src={Logo} alt="" />
          </div>
      </div>
      <div className="blur footer-blur-l"></div>
      <div className="blur footer-blur-r"></div>
    </div>
  )
}

export default footer