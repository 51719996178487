import React from 'react'
import Header from '../Header/header.jsx'
import './hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const hero = () => {
  const transition = {type: 'spring', duration: 3}
  const mobile=window.innerWidth<=786 ? true: false;
  return (
    <div className="hero" id='home'>

      <div className="blur hero-blur"></div>
      <div className="left-h">
            
        <Header/>

        <div className="tagline">
          <motion.div
          initial={{left:mobile ? "140px" : "238px"}}
          whileInView={{left:'8px'}}
          transition={{...transition, type:'tween'}}
          ></motion.div>
          <span>The Best Fitness Club in The Town</span>
        </div>

      <div className="title">
        <div>
          <span className='stroke-text'>Shape</span>
          <span> Your</span>
        </div>
        <div>
          <span>Ideal Body</span>
        </div>
        <div>
          <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
        </div>
      </div>

      <div className="figures">
        <div>
          <span>
            <NumberCounter end={140} start={100} delay='4' preFix='+'/>
          </span>
          <span>EXPERT COACHES</span>
        </div>
        <div>
          <span>
            <NumberCounter end={978} start={800} delay='4' preFix='+'/>
          </span>
          <span>members joined</span>
        </div>
        <div>
          <span>
          <NumberCounter end={50} start={42} delay='4' preFix='+'/>
          </span>
          <span>fitness programs</span>
        </div>
      </div>

      <div className="hero-btn">
        <button className="btn">Get Started</button>
        <button className="btn">Learn More</button>
      </div>
        
    </div>

    <div className="right-h">
      
      <button className='btn'>Join Now</button>
      
      <motion.div className="heart-rate"
      transition={transition}
      initial={{right:'-1rem'}}
      whileInView={{right:'4rem'}}>
        <img src={Heart} alt="" />
        <span>Heart Rate</span>
        <span>116 bpm</span>
      </motion.div>

      <img src={hero_image} alt="" className='hero_image'/>
      <motion.img src={hero_image_back} alt="" className='hero_image_back'
      transition={transition}
      initial={{right:'11rem'}}
      whileInView={{right:'28rem'}}/>

      <motion.div className="calories"
      transition={transition}
      initial={{right:'37rem'}}
      whileInView={{right:'28rem'}}>
        <img src={Calories} alt="" />
        <div>
          <span>Calories Burned</span>
          <span>220 kcal</span>
        </div>
      </motion.div>

    </div>
  </div>
  )
}

export default hero